<template>
  <div
    class="align-items-center p-3 mt1r rounded bs-shadow upload-lightbox-card"
    style="
      background: #fff;
      padding: 20px;
      min-width: 300px;
      display: block;
      position: relative;
    "
  >
    <h2
      @click="closeLightbox"
      style="
        position: absolute;
        right: 10px;
        top: 4px;
        cursor: pointer;
        line-height: 1;
        z-index: 1000;
      "
    >
      &#215;
    </h2>

    <div class="container">
      <div class="row">
        <div class="col-xs-12 col-12">
          <h6 style="text-align: center">Upload Your Project</h6>

          <hr />

          <div class="w100">
            <div class="top-wrap">
              <div class="dflex mb10" v-if="firstName && lastName">
                <avatar
                  v-once
                  class="mr5"
                  color="#fff"
                  v-bind:username="firstName + ' ' + lastName"
                  v-bind:backgroundColor="
                    colorGetter[Math.floor(Math.random() * colorGetter.length)]
                  "
                ></avatar>

                <div class="dflex vcenter">
                  <div style="text-align: left">
                    <h6 class="mb0">
                      <b>{{ firstName }} {{ lastName }}</b>
                    </h6>
                    <p class="lh1 mb0" style="max-width: 150px">
                      <small>{{ company }}</small>
                    </p>
                  </div>
                </div>
              </div>
              <div class="mlauto dflex vcenter">
                <p class="lh1 mb0"></p>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-xs-12 col-md-8 col-12">
              <div class="form-group">
             
                <label for="exampleFormControlSelect1" style="display: none"
                  >* Products <small>Please select all that apply</small></label
                >
                <v-select
                  multiple
                  label="materialId"
                  v-model="productTypesSelected"
                  :options="productTypes"
                  class="style-chooser"
                  placeholder="Choose Products"
                  style="
                    background: #e6edf2;
                    border: none !important;
                    padding: 0.175rem 0.75rem 0.175rem 0.35rem;
                    border-radius: 4px;
                  "
                  :selectable="
                    () =>
                      productTypesSelected && productTypesSelected.length < 2
                  "
                />
      
              </div>

              <!--
            <label for="product" style="display: none; text-align: left;"><small>Product:</small></label>
            <select
              id="product"
              class="form-control"
              v-model="selectedProduct"
              style="margin-bottom: 20px; background: #e6edf2; border: none; margin-bottom: .9rem;"
            >
              <option
                :value="null"
                selected
                class="all-contractors"
                id="firstSelector"
                style="text-transform: capitalize !important"
              >
                Select a Product
              </option>
              <option
                v-for="product in products"
                :key="product.id"
                :value="product.name"
                id="text"
              >
                {{ product.name }}
              </option>
            </select>
            --></div>

            <div class="col-xs-12 col-md-4 col-12">
              <label for="market" style="display: none; text-align: left"
                ><small>Market:</small></label
              >
              <select
                id="market"
                class="form-control"
                v-model="selectedMarket"
                style="background: #e6edf2; border: none; margin-bottom: 0.9rem"
              >
                <option
                  :value="null"
                  selected
                  class="all-contractors"
                  id="firstSelector"
                  style="text-transform: capitalize !important"
                >
                  Market
                </option>
                <option
                  v-for="market in markets"
                  :key="market.id"
                  :value="market.name"
                  id="text"
                >
                  {{ market.name }}
                </option>
              </select>
            </div>
          </div>

          <div class="row">
            <div class="col-xs-12 col-md-8 col-12">
              <div class="form-group">
                <label for="exampleFormControlSelect1" style="display: none"
                  >* Material <small>Please select all that apply</small></label
                >
             
                <v-select
                  multiple
                  label="materialId"
                  v-model="materialTypesSelected"
                  :options="materialTypes"
                  class="style-chooser"
                  placeholder="Choose Material"
                  style="
                    background: #e6edf2;
                    border: none !important;
                    padding: 0.175rem 0.75rem 0.175rem 0.35rem;
                    border-radius: 4px;
                  "
                  :selectable="
                    () =>
                      materialTypesSelected && materialTypesSelected.length < 2
                  "
                />
             
              </div>

              <!--
          <label for="material" style="display: none; text-align: left;"><small>Material:</small></label>
            <select
              id="material"
              class="form-control"
              v-model="selectedMaterial"
              style="margin-bottom: 20px; background: #e6edf2; border: none; margin-bottom: .9rem;"
            >
              <option
                :value="null"
                selected
                class="all-contractors"
                id="firstSelector"
                style="text-transform: capitalize !important"
              >
                Material Stored
              </option>
              <option
                v-for="material in materialStored"
                :key="material.id"
                :value="material.name"
                id="text"
              >
                {{ material.name }}
              </option>
            </select>
          -->
            </div>
            <div class="col-xs-12 col-md-4 col-12">
              <label for="market" style="display: none; text-align: left"
                ><small>Project Type:</small></label
              >

              <select
                id="market"
                class="form-control"
                v-model="selectedProjectType"
                style="background: #e6edf2; border: none; margin-bottom: 0.9rem"
              >
                <option
                  :value="null"
                  selected
                  class="all-contractors"
                  id="firstSelector"
                  style="text-transform: capitalize !important"
                >
                  Project Type
                </option>
                <option
                  v-for="project in projectTypes"
                  :key="project.id"
                  :value="project.name"
                  id="text"
                >
                  {{ project.name }}
                </option>
              </select>
            </div>
          </div>

          <form class="w100" style="margin-bottom: 0">
            <div class="form-group mb-0">
              <input
                autofocus
                class=""
                type="text"
                style="
                  padding: 15px;
                  width: 100%;
                  border-radius: 4px;
                  border: none;
                  background: #e6edf2;
                  margin-bottom: 15px;
                "
                :placeholder="'What\’s your unique application?'"
                v-model="postTitle"
              />
              <div class="form-group">
                <textarea
                  class="form-control"
                  id="exampleFormControlTextarea1"
                  rows="6"
                  style="
                    padding: 15px;
                    width: 100%;
                    border-radius: 4px;
                    border: none;
                    background: #e6edf2;
                  "
                  placeholder="Give a brief description of the project challenges and how they were addressed. What details make this project stand out? Please provide customer and project name if possible."
                  v-model="postBody"
                ></textarea>
                <!--
				<ckeditor 
                      v-model="postBody"
                      
                      :config="editorConfig"  
                      @ready="onEditorReady"                    
                      ></ckeditor>
                      -->
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <!-- #################### end first row ###################### -->
    <div class="container">
      <div class="row">
        <div class="col-xs-12 col-12">
          <form enctype="multipart/form-data">
            <div class="file is-boxed is-primary" style="text-align: center">
              <input
                v-if="files.length > 10"
                disabled
                multiple
                type="file"
                ref="files"
                class="file-input"
                aria-describedby="upload files"
                placeholder="Upload Files"
                @change="selectFile"
                style="display: none"
                id="img"
              />
              <input
                v-else
                multiple
                type="file"
                ref="files"
                class="file-input"
                aria-describedby="upload files"
                placeholder="Upload Files"
                @change="selectFile"
                style="display: none"
                id="img"
                accept="image/heic,
				image/jpeg, image/png"
              />

              <label
                for="img"
                class="label file-input"
                style="
                  width: 100%;
                  background: rgb(230, 237, 242);
                  margin-bottom: 1rem;
                "
              >
                <span class="file-cta">
                  <span class="material-icons" style="color: #6c757d">
                    insert_photo
                  </span>

                  <div class="file-label" style="color: #6c757d">
                    Choose Images
                  </div>
                </span>
              </label>
            </div>

            <div
              v-if="message || fileCountError.length || files.length"
              class="message-wrap"
              style="margin-bottom: 1rem"
            >
              <div
                v-if="message && fileCountError == ''"
                :class="`message`"
                style="font-size: 14px; font-weight: bold; color: #6c757d"
              >
                {{ message }}
                <hr />
              </div>
              <div
                v-else-if="fileCountError.length"
                :class="`message `"
                style="font-size: 14px; font-weight: bold; color: #6c757d"
              >
                {{ fileCountError }}
                <hr />
              </div>
              <div v-if="files.length && !sending">
                <div
                  v-for="(file, index) in files"
                  :key="index"
                  :class="`edit-list ${file.invalidMessage && 'text-danger'}`"
                >
                  <div class="file-left">
                    <div class="file-item" style="font-size: 14px">
                      {{ file.name
                      }}<span v-if="file.invalidMessage"
                        >&nbsp;- <small>{{ file.invalidMessage }}</small></span
                      >
                    </div>
                  </div>
                  <div class="file-right">
                    <div class="file-item">
                      <button @click.prevent="removeFromList(index)">
                        <span class="material-icons"> clear </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else-if="sending">
                <h5>Please be patient, we are processing your files</h5>
              </div>
            </div>

            <!--
            <div v-if="uploadedImages.length" class="image-wrap">
              <div
                v-for="image in uploadedImages"
                :key="image.file"
                class="image-box"
                v-bind:style="{
                  backgroundImage:
                    'url(http://localhost:5002/images/' + image.file + ')',
                }"
                style="background-size: cover; background-position: center;"
              ></div>
            </div>
			-->

            <button
              v-if="
                !files.length ||
                fileCountError != '' ||
                !postTitle ||
                !postBody ||
                !productTypesSelected.length ||
                !selectedMarket ||
                !materialTypesSelected.length ||
                !selectedProjectType ||
                sending
              "
              disabled
              type="submit"
              class="btn btn-primary post-btn"
              style="align-items: center; display: flex; margin-left: auto; border: 2px solid #dedede!important; font-size: 16px!important; padding: 10px 10px!important;"
            >
              Post
            </button>

            <button
              v-else-if="files.length && fileCountError == ''"
              @click.prevent="sendFile()"
              type="submit"
              class="btn btn-primary post-btn"
              style="align-items: center; display: flex; margin-left: auto"
            >
              Post
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import _ from "lodash";
import Avatar from "vue-avatar";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import vSelect from "vue-select";
import "vue-multiselect/dist/vue-multiselect.min.css";
import "vue-select/dist/vue-select.css";

export default {
  name: "MultipleUpload",
  components: { Avatar, ClassicEditor, vSelect },
  data() {
    return {
      server: process.env.VUE_APP_SERVER_URL,
      files: [],
      uploadFiles: [],
      message: "",
      error: false,
      fileCountError: "",
      allowFileCount: 15,
      sending: false,
      uploadedImages: [],
      UserFirstName: this.$store.state.user.FirstName,
      postTitle: null,
      postBody: null,
      selectedProduct: null,
      selectedMarket: null,
      selectedMaterial: null,
      selectedProjectType: null,
      productTypesSelected: [],
      materialTypesSelected: [],
      editorConfig: {
        // The configuration of the editor.
        uiColor: "#e6edf2",
        toolbar: [
          { name: "basicstyles", items: ["Bold"] },
          { name: "paragraph", items: ["NumberedList", "BulletedList"] },
          { name: "links", items: ["Link"] },
        ],
      },
    };
  },
  props: {
    closeLightbox: Function,
    firstLoad: Function,
    markets: Array,
    products: Array,
    materialStored: Array,
    projectTypes: Array,
    productTypes: Array,
    materialTypes: Array,
    ETO: Boolean,
  },
  methods: {
    selectFile() {
      this.disabled = false;
      this.message = "";
      //
      const files = this.$refs.files.files;
      if (files.length > this.allowFileCount) {
        this.fileCountError = `Sorry, only ${this.allowFileCount} files are allowed`;
      } else {
        this.fileCountError = "";
      }

      //set files to files that are already there, and add the files that the user just selected
      //the actual javascript files object to be uploaded
      this.uploadFiles = [...this.uploadFiles, ...files];

      this.files = [
        ...this.files,
        ..._.map(files, (file) => ({
          name: file.name,
          size: file.size,
          type: file.type,
          invalidMessage: this.validate(file),
        })),
      ];
    },

    validate(file) {
      const MAX_SIZE = 12 * 1024 * 1024; // 4 MB
      const allowedTypes = [
        "image/jpeg",
        "image/png",
        "image/gif",
        "image/heic",
      ];
      if (file.size > MAX_SIZE) {
        this.error = true;
        this.message = "Problematic files will be ignored";
        return `The max file size is 7mb`;
      }

      if (file.name.includes(".exe") || file.name.includes(".dmg")) {
        this.error = true;
        this.message = "Problematic files will be ignored";
        return `An executable file, really?`;
      }

      if (!allowedTypes.includes(file.type)) {
        this.error = true;
        this.message = "Problematic files will be ignored";
        return `Sorry, but this file isn't an image`;
      }
      return "";
    },

    async sendFile() {
      // the address "https://spacenet-v3-images.s3.us-east-2.amazonaws.com/1601659175930-42792795_1946666015413774_7693230761595371520_n.jpg"
      this.sending = true;
      this.message = "Please be patient, we are processing your files";

      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: "Bearer " + localStorage.getItem("SSCUSER"),
        },
      };

      console.log(`${this.server}/api/v1/cool-installs/create-post`);

      let postData = {
        postTitle: this.sanitizeInput(this.postTitle),
        postBody: this.sanitizeInput(this.postBody),
        UserId: this.UseridNumber,
        selectedMarket: this.selectedMarket,
        primaryProduct: this.productTypesSelected.length
          ? this.productTypesSelected[0]
          : null,
        secondaryProduct:
          this.productTypesSelected.length > 1
            ? this.productTypesSelected[1]
            : null,
        primaryMaterial: this.materialTypesSelected.length
          ? this.materialTypesSelected[0]
          : null,
        secondaryMaterial:
          this.materialTypesSelected.length > 1
            ? this.materialTypesSelected[1]
            : null,
        MaterialStored: this.selectedMaterial,
        selectedProjectType:
          this.selectedProjectType == "Standard Product" ? 0 : 1,
      };

      axios
        .post(
          `${this.server}/api/v1/cool-installs/create-post`,
          postData,
          axiosConfig
        )
        .then((response) => {
          // this.sending = false;
          let ID = response.data[0].ID;
          console.log(ID);
          console.log("post data " + JSON.stringify(postData));

          const formData = new FormData();
          console.log("form data " + JSON.stringify(formData));
          //formData.append("file", this.file);
          _.forEach(this.uploadFiles, (file) => {
            if (this.validate(file) === "") {
              formData.append("files", file);
            }
          });

          axios
            .post(`${this.server}/api/v1/cool-installs/upload/${ID}`, formData)
            .then((response) => {
              this.sending = false;
              console.log(response);
              this.uploadedImages = response.data.files;
              this.message = "Files have been uploaded!";
              this.files = [];
              this.uploadFiles = [];
              this.error = false;
              window.location.href = "/eto-and-inspiring-installs";
              // console.log("posting");
            })
            .catch((err) => {
              this.sending = false;
              console.log(err);
              this.message = err.response.data.error;
              this.message = "something went wrong";
              this.error = true;
            });
        })
        .catch((err) => {
          this.sending = false;
          console.log(err);
          this.message = err.response.data.error;
          this.message = "something went wrong";
          this.error = true;
        });

      /*
			try {
				await axios.post("http://localhost:3344/multiple", formData);
				this.message = "Files have been uploaded!";
				this.files = [];
				this.uploadFiles = [];
				this.error = false;
				//console.log("posting");
			} catch (err) {
				console.log(err);
				this.message = err.response.data.error;
				this.message = "something went wrong";
				this.error = true;
      }
      */
    },
    removeFromList(index) {
      this.files.splice(index, 1);
      this.uploadFiles.splice(index, 1);
      if (this.files.length > this.allowFileCount) {
        this.fileCountError = `Sorry, only ${this.allowFileCount} files are allowed`;
      } else {
        this.fileCountError = "";
        /*
				_.forEach(this.uploadFiles, (file) => {
					if (this.validate(file) !== "") {
						this.message = "Problematic files will be ignored";
					} else {
						this.message = "Good to go!";
					}
                });
                */
      }
    },
    sanitizeInput: function (input) {
      // Escape single quotes
      const escapedInput = input.replace(/'/g, "''");

      // Escape double quotes
      // Note: If you're using MySQL, you may want to escape double quotes with a backslash instead: input.replace(/"/g, '\\"')
      const escapedInputWithDoubleQuotes = escapedInput.replace(/"/g, '""');

      return escapedInputWithDoubleQuotes;
    },
  },
  computed: {
    watchFileCount: function () {
      if (this.uploadFiles.length > this.allowFileCount) {
        this.fileCountError = `Sorry, only ${this.allowFileCount} files are allowed`;
      } else {
        this.fileCountError = "";
      }
    },
    firstName() {
      return this.$store.state.user.FirstName;
    },
    lastName() {
      return this.$store.state.user.LastName;
    },
    company() {
      return this.$store.state.user.Company;
    },
    colorGetter() {
      return this.$store.state.colors;
    },
    UseridNumber() {
      return this.$store.state.user.UserId;
    },
  },
};
</script>
<style>
.file-input {
  background: #00c7c7;
  padding: 20px;
  border-radius: 4px;
  color: #fff;
}
.file-input .material-icons {
  font-size: 2em;
}
.flex-btn {
  display: flex;
  justify-content: center;
  align-content: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0.5rem;
}
.text-success {
  color: #00c7c7 !important;
}
.edit-list {
  display: flex;
  padding: 5px 10px;
  background: rgb(230, 237, 242);
  margin-bottom: 5px;
  border-radius: 4px;
}
.edit-list .file-right {
  margin-left: auto;
}
.edit-list .file-left {
  margin-right: 20px;
  text-align: left;
}
.file-item button {
  border: none;
  border-radius: 50%;
  padding: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #000;
  color: #fff;
  cursor: pointer;
  border: none !important;
  outline: none !important;
}
.file-item .material-icons {
  line-height: 1;
  font-size: 13px;
}
button:disabled {
  background: rgba(0, 0, 0, 0.2) !important;
  border: none !important;
}
.image-wrap {
  display: flex;
  flex-wrap: wrap;
}
.image-box {
  width: 100px;
  height: 100px;
  overflow: hidden;
  margin: 0 10px 10px 0;
}
.image-box img {
  width: 100%;
  height: auto;
}
.upload-lightbox-card {
  width: 600px;
}
.message-wrap {
  max-height: 122px;
  overflow-y: scroll;
  background: #e6edf2;
  padding: 10px;
  border-radius: 4px;
}

.post-btn {
  background: #429da8;
  color: #fff;
  transition: all 0.3s ease 0s;
  border: 2px solid #429da8;
  cursor: pointer;
  font-size: 16px;
  padding: 10px 10px;
}
.vs__dropdown-toggle {
  border: none;
}
.vs__selected {
  border: none;
  background: #fff;
  font-size: 15px;
}

.vs__dropdown-option--highlight {
  background: #429da8;
  color: #fff;
}
</style>
