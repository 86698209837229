<template>
    <div>      
      <div class="dflex mrauto" style="justify-content: center; align-items: center; position: relative; display: flex; align-items: center; justify-content: center;">
        <!-- Display liked.svg when hovering -->
        <img v-if="hovered && !liked" @click="like" class="like" style="max-width: 20px;" src="/liked.svg" @mouseover="hovered = true" @mouseleave="hovered = false"/>
    
        <img v-else-if="liked" @click="unlike" class="like" style="max-width: 20px;" src="/liked.svg" @mouseover="hovered = true" @mouseleave="hovered = false"/>
    
        <!-- Display not-liked.svg by default -->
        <img v-else @click="like" class="not-liked like" style="max-width: 20px;" src="/not-liked.svg" @mouseover="hovered = true" @mouseleave="hovered = false"/>
        
        
        <div class="mb0 lh1 liked-users" style="margin-left: 3px; position: relative;">
          <p  
          @mouseover="showUserList"
          @mouseleave="hideUserList"
          style="margin-bottom: 0;"
          class="like-number"
          ><small>{{ (localLikes > 0 && localLikes < 3 ) ? localLikes : null }} {{ (localLikes >= 3 ) ? LikedUserArrayList[0] + ", " + LikedUserArrayList[1] + " and " + (localLikes - 2) + " others": null }}</small></p>

          <!-- Two names and number others-->
        </div>

        <span v-if="LikedUserArrayList.length && userListHovered" class="liked-users-list">
                <p v-for="name in likedUsersToShow" :key="name" class="lh1" style="margin-bottom: 2px;"><small style="font-size: 12px;">{{ name }}</small></p>
                <!-- Facebook Shows 17 Here -->
                <p v-if="remainingCount > 0" class="lh1" style="margin-bottom: 2px;">
                    <small style="font-size: 12px;">and {{ remainingCount }} more....</small>
                </p>
        </span>
      </div>
      
    </div>
  </template>
  
  <script>
  import axios from "axios";

  export default {
    name: 'Likes',
    props: {
      LikePost: Function,
      LikeCount: Number,
      LikedUsers: Array,
      userID: String,
      PostID: Number,
      isLiked: Boolean
    },
    data() {
      return {
        server: process.env.VUE_APP_SERVER_URL,
        hovered: false,
        liked: this.isLiked,
        localLikes: 0, // Initialize localLikes with LikeCount
        LikedUserArrayList: [],
        userListHovered: false,
      };
    },

    created() {
        // Initialize LikedUserArrayList       
        // this.LikedUserArrayList = this.LikedUsers.split(', ');
        // Initialize localLikes
        this.LikedUsers ? this.LikedUserArrayList = this.LikedUsers : this.LikedUserArrayList = [];
        this.localLikes = this.LikedUserArrayList.length;
    },

    methods: {

   
      like() {
        // /cool-installs/like/:id
        this.liked = true;
        this.localLikes++; // Increment localLikes
        this.LikedUserArrayList.push(this.fullName)        
        // console.log(this.LikedUserArrayList)

        let axiosConfig = {
            headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Authorization: "Bearer " + localStorage.getItem("SSCUSER"),
            },
        };

        let postData = {
            PostID: this.PostID,
            UserID: this.USERID,
        };

        // alert(JSON.stringify(postData))
        // console.log(`${this.server}/api/v1/cool-installs/like/${this.PostID}`);


        // console.log('adding');
        // console.log(JSON.stringify(postData))

        /*
        try {
				axios.post(`${this.server}/api/v1/cool-installs/like/${this.PostID}`, postData);
				alert("Worked")
        } catch (err) {
          console.log(err);
          alert("Error")
        }
        */

    
        
        return axios.post(`${this.server}/api/v1/cool-installs/like/${this.PostID}`, postData, axiosConfig).then(response => {
          // console.log(response);

          if (response.status === 401) {

            window.location = "/logout";
            
          } else {

            // this.$noty.success("Successfully liked post!");
            console.log(response.data);

          }
        })
        .catch(error => {

          this.$noty.error("There was an issue liking the post");
          console.log(error);

        });
        
       
      },


      unlike() {
        // /cool-installs/unlike/:id
        this.liked = false;
        this.localLikes--; // Decrement localLikes      
        let newArray = this.LikedUserArrayList.filter(item => item !== this.fullName);
        this.LikedUserArrayList = newArray;

        // console.log('removing')
        // console.log(this.LikedUserArrayList)
        let axiosConfig = {
            headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Authorization: "Bearer " + localStorage.getItem("SSCUSER"),
            },
        };

        let postData = {
            PostID: this.PostID,
            UserID: this.USERID,
        };

        return axios.post(`${this.server}/api/v1/cool-installs/unlike/${this.PostID}`, postData, axiosConfig).then(response => {
          // console.log(response);

          if (response.status === 401) {

            window.location = "/logout";
            
          } else {

            // this.$noty.success("Successfully liked your post!");
            // console.log(response.data);
            // this.$noty.success("Successfully unliked post!");

          }
        })
        .catch(error => {

          // this.$noty.error("There seems to be an issue posting your content");
          console.log(error);
          this.$noty.error("There was an issue unliking the post");

        });
      },
   
      showUserList(){
        this.userListHovered = true;
      },
      hideUserList() {
        this.userListHovered = false;
      },
    },
    computed: {
      // Determine if the like button is currently hovered
      firstName() {
        return this.$store.state.user.FirstName;
      },
      lastName() {
        return this.$store.state.user.LastName;
      },
      fullName() {
          return this.$store.state.user.FirstName + " " + this.$store.state.user.LastName;
      },
      company() {
        return this.$store.state.user.Company;
      },
      USERID(){
        return this.$store.state.user.UserId;
      },
      likedUsersToShow() {
        return this.LikedUserArrayList.slice(0, 10);
      },
      remainingCount() {
        return Math.max(0, this.LikedUserArrayList.length - 10);
      }
    }
}
  </script>
  
  <style scoped>
  .like {
    /* Set initial size and transition */
    transition: transform 0.3s ease;
    cursor: pointer;
  }
  
  .like:hover {
    /* Scale to 105% size on hover */
    transform: scale(1.10);
  }
  .liked-users-list {
    position: absolute;
    top: 25px;
    padding: 10px;
    border-radius: 4px;
    background: rgba(0,0,0,0.8); 
    color: #fff; 
    position: absolute; 
    display: block; 
    width: auto; 
    left: 40px;
    z-index: 1000;
    min-width: 200px;
  }
  .like-number{
    cursor: pointer;
  }
  .like-number:hover{
    text-decoration: underline;
  }
  </style>
  