<template>
  <div class="cool-installs inside-banner mobile-top">
    <transition name="menu-popover">
      <div v-if="lightbox" class="lightbox">
        <h2 class="closeX" @click="closeLightbox()">
          <a>&#215;</a>
        </h2>
        <multiple-upload
          :closeLightbox="closeLightbox"
          :firstLoad="firstLoad"
          :markets="markets"
          :products="products"
          :materialStored="materialStored"
          :projectTypes="projectTypes"
          :productTypes="productTypes"
          :materialTypes="materialTypes"
          :ETO="true"
        />
      </div>
    </transition>

    <section class="py-5 bg8 bs-shadow" style="padding-top: 20px!important; padding-bottom:20px!important;">
      <div class="container">
        <!--
        <h1 v-if="showButton">YES</h1>
        <h1 v-else>NO</h1>
        -->
        <div class="row">
        <div
          class="col-xs-12 col-sm-12 col-12 col-lg-5"
    
        >
<div style="display: flex; height: 100%; align-items: center;">
  <h1 class="page-heading" style="color: #fff; font-size: 31px;">ETO & Inspiring Installs</h1>
</div>
        
     
       </div>
       <div
          class="col-xs-12 col-sm-12 col-12 col-lg-7"
    
        >

     
        <h5 style="color: #fff; margin-bottom: 19px;">Showcase your most impressive installations, share photos, and explain the unique features that make them stand out.</h5>
       </div>
      
      </div>
        <div id="yes" class="input-wrap">
          <input
            class="top-search"
            type="text"
            placeholder="Search by project details (for example, oversized)"
            v-model="search"
            style="padding: 15px;"
            @keydown="handleKeydown"
          />

          <button
            :class="[showButton ? 'clear-btn' : 'clear-hide']"
            @click="clearSearch"
            type="button"
            class="d-flex align-items-center pr-3 clear-btn"
          >
            <svg
              class="w-4 h-4 text-[#429da8] hover:text-[#1E3040]"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
            >
              <path fill="none" d="M0 0h24v24H0z"></path>
              <path
                d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"
              ></path>
            </svg>
          </button>
        </div>

        <!--
      <div v-else id="no">
        <input
          class="top-search"
          type="text"
          placeholder="Search ETO project"
          v-model="search"
          style="padding: 15px;"
        />
      </div>
    -->

        <div class="search-btns" style="display: flex; margin-bottom: 0">
          <div class="left-side" style="margin-right: 10px">
            <div
              id="listings-step-2"
              class="ac-select"
              style="margin-right: 0; display: flex;"
            >
              <select
                class="form-control"
                v-model="selectedProduct"
                style="margin-right: 10px;"
              >
                <option
                  :value="null"
                  selected
                  class="all-contractors"
                  id="firstSelector"
                  style="text-transform: capitalize !important"
                >
                  All Products
                </option>
                <option
                  v-for="product in products"
                  :key="product.id"
                  :value="product.name"
                  id="text"
                >
                  {{ product.name }}
                </option>
              </select>

              <select class="form-control" v-model="selectedMarket">
                <option
                  :value="null"
                  selected
                  class="all-contractors"
                  id="firstSelector"
                  style="text-transform: capitalize !important"
                >
                  All Markets
                </option>
                <option
                  v-for="market in markets"
                  :key="market.id"
                  :value="market.name"
                  id="text"
                >
                  {{ market.name }}
                </option>
              </select>
            </div>
          </div>

          <div class="right-side" style="display: flex">
            <div
              class="search-btns"
              style="display: flex; margin-top: 0; margin-left: auto"
            >
              <button
                v-if="search || products.length || market || material"
                @click="updateURL"
                class="search"
              >
                <span class="material-icons">search</span>
                {{ " " }} Search
              </button>
              <button v-else class="search" disabled>
                <span class="material-icons">search</span>
                {{ " " }} Search
              </button>
            </div>
          </div>
        </div>

        <div class="materials" style="display: inline-block; width: auto; margin-bottom: 5px;">
          <select class="form-control" v-model="selectedMaterial">
                <option
                  :value="null"
                  selected
                  class="all-contractors"
                  id="firstSelector"
                  style="text-transform: capitalize !important"
                >
                  All Materials
                </option>
                <option
                  v-for="material in materialStored"
                  :key="material.id"
                  :value="material.name"
                  id="text"
                >
                  {{ material.name }}
                </option>
              </select>
        </div>

        <div class="radios">
          <div class="control-group">
            <label class="control control--radio">
              All Projects
              <input
                type="radio"
                name="radio"
                checked="checked"
                v-model="ETOproject"
                value="false"
              />
              <div class="control__indicator"></div>
            </label>
            <label class="control control--radio">
              ETO
              <input
                type="radio"
                name="radio"
                v-model="ETOproject"
                value="true"
              />
              <div class="control__indicator"></div>
            </label>
          </div>
        </div>
      </div>
    </section>

    <div class="container">
      <div
        @click="openLightbox"
        class="coolInstall-header d-flex align-items-center mt1r rounded bs-shadow"
        style="padding: 20px"
      >
        <div
          class="
              question-header
              d-flex
              align-items-center
              rounded
              w-100
            "
        >
          <div class="w100 d-flex">
            <div class="top-wrap">
              <div class="d-flex mb10" v-if="firstName && lastName">
                <div>
                  <avatar
                    v-once
                    class="mr5"
                    color="#fff"
                    v-bind:username="firstName + ' ' + lastName"
                    v-bind:backgroundColor="
                      colorGetter[
                        Math.floor(Math.random() * colorGetter.length)
                      ]
                    "
                  ></avatar>
                </div>
              </div>
              <div class="mlauto dflex vcenter">
                <p class="lh1 mb0"></p>
              </div>
            </div>

            <form class="w100" style="margin-bottom: 0;">
              <div class="form-group mb-0">
                <input
                  class=""
                  type="text"
                  style="padding: 15px; width: 100%; border-radius: 4px; border: none; background: #e6edf2;"
                  :placeholder="'Share your ETO or inspiring install here'"
                />
              </div>
            </form>
          </div>
        </div>
      </div>

      <div class="row">
        <div
          v-if="loading == true"
          class="col-xs-12 col-sm-12 col-12 col-lg-12"
          style="margin: 100px 0;"
        >
          <div class="flex-box-wrap">
            <div class="flex-internal">
              <atom-spinner
                :animation-duration="1000"
                :size="40"
                :color="'#575757'"
              />
              <p>Loading......</p>
            </div>
          </div>
        </div>

        <div
          v-else-if="loading == false && !coolInstalls.length"
          class="col-xs-12 col-sm-12 col-12 col-lg-12 flexVHcenter"
        >
          <div style="margin: 30px 0;">
            <img src="@/assets/img/sad.svg" />
            <p>Sorry, nothing was found...</p>
          </div>
        </div>

        <div v-else class="col-xs-12 col-sm-12 col-12 col-lg-12">
          <div
            class="coolInstall-header d-flex align-items-center p-3 mt1r rounded bs-shadow"
          >
            <div class="d-flex number-showing">
              <p class="mb0">
                Page {{ currPage }} of
                {{ totalPage }}
              </p>
            </div>

            <div class="pag-wrapper mlauto">
              <div class="nav-btns">
                <button v-if="this.currPage === 1" disabled>
                  <i class="material-icons">keyboard_arrow_left</i> Back
                </button>

                <button v-else @click.prevent="setPage(currPage - 1)">
                  <i class="material-icons">keyboard_arrow_left</i> Back
                </button>

                <button
                  v-if="
                    this.currPage === this.totalPage ||
                      this.currPage ==
                      Math.ceil(this.coolInstalls[0].TotalPostCount / 14)
                  "
                  disabled
                >
                  Next
                  <i class="material-icons">keyboard_arrow_right</i>
                </button>

                <button v-else @click.prevent="setPage(currPage + 1)">
                  Next
                  <i class="material-icons">keyboard_arrow_right</i>
                </button>
              </div>
            </div>
          </div>

          <div>
            <div class="row">
              <div
                v-bind:key="coolInstall.ItemID"
                v-for="coolInstall in coolInstalls"
                class="col-12 col-sm-12 col-6 col-lg-6 block"
              >
               <install-card :Install="coolInstall" v-once />
              </div>
            </div>
          </div>

          <div
            class="coolInstall-header d-flex align-items-center p-3 mt1r rounded bs-shadow mb20"
          >
            <div class="d-flex number-showing">
              <p class="mb0">
                Page {{ currPage }} of
                {{ totalPage }}
              </p>
            </div>

            <div class="pag-wrapper mlauto">
              <div class="nav-btns">
                <button v-if="this.currPage === 1" disabled>
                  <i class="material-icons">keyboard_arrow_left</i> Back
                </button>

                <button v-else @click.prevent="setPage(currPage - 1)">
                  <i class="material-icons">keyboard_arrow_left</i> Back
                </button>

                <button
                  v-if="
                    this.currPage === this.totalPage ||
                      this.currPage ==
                        Math.ceil(this.filteredCoolInstalls[0].TotalPostCount / 14)
                  "
                  disabled
                >
                  Next
                  <i class="material-icons">keyboard_arrow_right</i>
                </button>

                <button v-else @click.prevent="setPage(currPage + 1)">
                  Next
                  <i class="material-icons">keyboard_arrow_right</i>
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- end other column -->
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { AtomSpinner } from "epic-spinners";
import Avatar from "vue-avatar";
import VLazyImage from "v-lazy-image";
import MultipleUploadAWS from "../components/Organisms/MultipleUploadAWS.vue";
import MultipleUpload from "../components/Organisms/MultipleUpload.vue";
import Likes from "../components/Organisms/Likes.vue";
import InstallCard from "../components/Organisms/InstallCard.vue";

const auth = {
  headers: {
    Authorization: "Bearer " + localStorage.getItem("SSCUSER"),
  },
};

export default {
  name: "ETOProjects",
  components: {
    AtomSpinner,
    Avatar,
    VLazyImage,
    MultipleUploadAWS,
    MultipleUpload,
    Likes,
    InstallCard
  },
  data() {
    return {
      server: process.env.VUE_APP_SERVER_URL,
      search: "",
      products: [],
      market: "",
      material: "",
      coolInstalls: [],
      selectedCat: "Show All",
      ETOproject: false,
      loading: true,
      searching: false,
      countOfPage: 14,
      currPage: 1,
      totalPage: 1,
      noCoolInstallFound: false,
      fullName:
        this.$store.state.user.FirstName +
        " " +
        this.$store.state.user.LastName,
      UserFirstName: this.$store.state.user.FirstName,
      lightbox: false,
      selectedProduct: null,
      productTypes: [
        "4-Post",
        "Activestor",
        "ActivRAC",
        "Art Rack",
        "Cantilever",
        "Day Use Locker",
        "Doors & Drawers",
        "Evidence Locker",
        "FrameWRX",
        "Grow Crosswalk",
        "Gun Racks",
        "Highbay Shelving",
        "Laterals",
        "Museum Cabinets",
        "Parachute/Universal Container",
        "Personal Storage Locker",
        "RaptorRAC Widespan",
        "Std. Mobile-Manual",
        "Std. Mobile-Mech Assist",
        "Std. Mobile-Powered",
        "UEWSS",
        "Universal Weapons Rack",
        "Weapon Rack III",
        "Wheelhouse-Manual/MA",
        "Wheelhouse-Powered",
        "Widespan Grow",
        "Xtend",
        "Other",
      ],
      products: [
        { id: 1, name: "4-Post" },
        { id: 2, name: "Activestor" },
        { id: 3, name: "ActivRAC" },
        { id: 4, name: "Art Rack" },
        { id: 5, name: "Cantilever" },
        { id: 6, name: "Day Use Locker" },
        { id: 7, name: "Doors & Drawers" },
        { id: 8, name: "Evidence Locker" },
        { id: 9, name: "FrameWRX" },
        { id: 10, name: "Grow Crosswalk" },
        { id: 11, name: "Gun Racks" },
        { id: 12, name: "Laterals" },
        { id: 13, name: "Museum Cabinets" },
        { id: 14, name: "Parachute/Universal Container" },
        { id: 15, name: "Personal Storage Locker" },
        { id: 16, name: "RaptorRAC Widespan" },
        { id: 17, name: "Std. Mobile-Manual" },
        { id: 18, name: "Std. Mobile-Mech Assist" },
        { id: 19, name: "Std. Mobile-Powered" },
        { id: 20, name: "UEWSS" },
        { id: 21, name: "Universal Weapons Rack" },
        { id: 22, name: "Weapon Rack III" },
        { id: 23, name: "Wheelhouse-Manual/MA" },
        { id: 24, name: "Wheelhouse-Powered" },
        { id: 25, name: "Widespan Grow" },
        { id: 26, name: "Xtend" },
        { id: 27, name: "Other" },
      ],
      selectedMarket: null,
      selectedMaterial: null,
      markets: [
        { id: 1, name: "Agriculture" },
        { id: 2, name: "Athletics" },     
        { id: 3, name: "Business" },
        { id: 6, name: "Healthcare" },
        { id: 5, name: "Higher Education" },     
        { id: 7, name: "K-12" },
        { id: 8, name: "Library" },
        { id: 9, name: "Manufacturing" },
        { id: 10, name: "Military-Government" },
        { id: 11, name: "Museum" },
        { id: 12, name: "Public Safety" },
      ],
      materialStored: [
        { id: 1, name: "Ammunition" },
        { id: 2, name: "Art-2D" },
        { id: 3, name: "Art-3D" },
        { id: 4, name: "Artifacts" },
        { id: 5, name: "Athletic Gear" },
        { id: 6, name: "Bikes/Bicycles" },
        { id: 7, name: "Bins" },
        { id: 8, name: "Books/Binders" },
        { id: 9, name: "Boxes" },
        { id: 10, name: "CDs/DVDs/Tapes/Film" },
        { id: 11, name: "Chemicals" },
        { id: 12, name: "Classroom Supplies" },
        { id: 13, name: "Clothing/Apparel" },
        { id: 14, name: "Collections" },
        { id: 15, name: "Costumes" },
        { id: 16, name: "Equipment" },
        { id: 17, name: "Evidence" },
        { id: 18, name: "Files/Folders" },
        { id: 19, name: "Food" },
        { id: 20, name: "Gear" },
        { id: 21, name: "Lab Samples/Materials" },
        { id: 22, name: "Linens" },
        { id: 23, name: "Medical Equipment/Devices" },
        { id: 24, name: "Medical Supplies" },
        { id: 25, name: "Merchandise" },
        { id: 26, name: "Musical Instruments" },
        { id: 27, name: "Office Supplies" },
        { id: 28, name: "Parachutes" },
        { id: 38, name: "Parts" },
        { id: 39, name: "Personal Gear" },
        { id: 40, name: "Pharmaceuticals" },
        { id: 41, name: "Pipes/Tubing" },
        { id: 42, name: "Plants-Indoor Ag/Vertical Grow" },
        { id: 43, name: "Raw Materials" },
        { id: 44, name: "Records" },
        { id: 45, name: "Samples" },
        { id: 46, name: "Sheet Music" },
        { id: 47, name: "Slides/Paraffin Blocks" },
        { id: 48, name: "Specimens " },
        { id: 49, name: "Technology/Gadgets/Electronics" },
        { id: 50, name: "Tools" },
        { id: 51, name: "Weapons/Optics" },
        { id: 52, name: "Other" },
      ],
      materialTypes: [
          "Ammunition",
          "Art-2D",
          "Art-3D",
          "Artifacts",
          "Athletic Gear",
          "Bikes/Bicycles",
          "Bins",
          "Books/Binders",
          "Boxes",
          "CDs/DVDs/Tapes/Film",
          "Chemicals",
          "Classroom Supplies",
          "Clothing/Apparel",
          "Collections",
          "Costumes",
          "Equipment",
          "Evidence",
          "Files/Folders",
          "Food",
          "Gear",
          "Lab Samples/Materials",
          "Linens",
          "Medical Equipment/Devices",
          "Medical Supplies",
          "Merchandise",
          "Musical Instruments",
          "Office Supplies",
          "Parachutes",
          "Parts",
          "Personal Gear",
          "Pharmaceuticals",
          "Pipes/Tubing",
          "Plants-Indoor Ag/Vertical Grow",
          "Raw Materials",
          "Records",
          "Samples",
          "Sheet Music",
          "Slides/Paraffin Blocks",
          "Specimens",
          "Technology/Gadgets/Electronics",
          "Tools",
          "Weapons/Optics",
          "Other"
      ],
      projectTypes: [
        { id: 1, name: "Standard Product" },
        { id: 2, name: "ETO Project" },
      ],
    };
  },
  filters: {
    truncate: function(value) {
      if (value.length > 200) {
        value = value.substring(0, 200) + "...";
      }
      return value;
    },
    truncateTitle: function(value) {
      if (value.length > 50) {
        value = value.substring(0, 50) + "...";
      }
      return value;
    },
  },
  methods: {
    firstLoad: function() {

      this.loading = true;
      const urlParams = new URLSearchParams(window.location.search);
      const search = urlParams.get("search");
      const product = urlParams.get("product");
      const market = urlParams.get("market");
      const project = urlParams.get("project");
      const material = urlParams.get("material");
      const page = Number(urlParams.get("page"));

      if (page) {
        // this.setPage(page);
        this.currPage = page;
        console.log("changing");
      } else {
        this.currPage = 1;
      }

      // Build the URL parameters based on the extracted parameters only when they exist
      const queryParams = [];
      if (search) {
        queryParams.push(`search=${encodeURIComponent(search)}`);
        this.search = search;
      }
      if (product) {
        queryParams.push(`product=${encodeURIComponent(product)}`);
        this.selectedProduct = product;
      }
      if (market) {
        queryParams.push(`market=${encodeURIComponent(market)}`);
        this.selectedMarket = market;
      }
      if (project) {
        queryParams.push(`project=${encodeURIComponent(project)}`);
        //project=eto
        this.ETOproject = "true";
      }
      if (material) {
        queryParams.push(`material=${encodeURIComponent(material)}`);
        this.selectedMaterial = material;
      }
      if (page) queryParams.push(`page=${encodeURIComponent(page)}`);

      // Join all the query parameters with '&'
      const queryString = queryParams.join('&');

      // Construct the final URL
      const apiUrl = `${this.server}/api/v1/cool-installs/${this.USERID}?${queryString}`;
      console.log(queryParams);

      if (
        !this.search &&
        !this.selectedProduct &&
        !this.selectedMarket &&
        !this.ETOproject &&
        !this.selectedMaterial &&
        !page
      ) {
        // console.log(`${this.server}/api/v1/cool-installs/${this.USERID}`);
        this.searching = false;
        return axios
          .get(`${this.server}/api/v1/cool-installs/${this.USERID}`, auth)
          .then((response) => {
            this.coolInstalls = response.data;
            this.totalPage = Math.ceil(this.coolInstalls[0].TotalPostCount / 14);


            console.log("page " + page)
            if(page > 1){
              this.currPage = parseInt(page);
            }  

            console.log("first load " + this.$route.query.page);
            // this.$route.query.page ? this.currPage = this.$route.query.page : this.currPage = 1;
            this.loading = false;
          })
          .catch((error) => {
            console.error("Error loading data:", error);
            this.loading = false;
          });
      } else {

        console.log("api url " + apiUrl);
        this.searching = true;
        return axios
          .get(apiUrl, auth)
          .then((response) => {
            console.log(response);
            this.coolInstalls = response.data;
            this.searching = false;

            if(search || product || market || project || material) {
              console.log("has a URL var")
              this.currPage = Number(1);            
              // this.totalPage = Math.ceil(this.coolInstalls.length / 14);
              this.totalPage = Math.ceil(this.coolInstalls[0].TotalPostCount / 14);
              console.log("total page " + this.totalPage)
              // this.currPage = parseInt(1);
            } else {
              this.totalPage = Math.ceil(this.coolInstalls[0].TotalPostCount / 14);
            }
          
            console.log("page " + page)

            if(!search || !product || !market || !project || !material && page == 1){

              if(page == 0){
                console.log("page = 0")
                this.currPage = Number(1);
              } else {
                this.currPage = Number(page);
              }
            }     

            this.loading = false;
          })
          .catch((error) => {
            console.error("Error loading data:", error);
            this.loading = false;
          });
      }

    },

    handleKeydown(event) {
        if (event.keyCode === 13) { // Check if Enter key is pressed
            this.updateURL(); // Call the updateURL function
        }
    },




    updateURL() {
      const queryParams = [];
      //const page = urlParams.get("page") || "";
      if (this.search.trim().length > 0) {
        queryParams.push(`search=${encodeURIComponent(this.search.trim())}`);
      }
      if (this.selectedProduct) {
        queryParams.push(`product=${encodeURIComponent(this.selectedProduct)}`);
      }
      if (this.selectedMarket) {
        queryParams.push(`market=${encodeURIComponent(this.selectedMarket)}`);
      }
      if (this.ETOproject == "true") {
        queryParams.push(`project=${encodeURIComponent("eto")}`);
      }
      if (this.selectedMaterial) {
        queryParams.push(`material=${encodeURIComponent(this.selectedMaterial)}`);
      }
      /*
      if (page) {
        queryParams.push(`page=${encodeURIComponent(page)}`);
      }
      */

      const newURL = `${window.location.pathname}?${queryParams.join("&")}`;
      window.history.pushState({}, "", newURL);
      this.firstLoad();
      // window.location.href = newURL;
    },

    resetURL: function(){
      // Get the base URL without query parameters
      const baseURL = this.$route.path;

      // Replace the current history entry with the new base URL
      history.replaceState(null, '', baseURL);
    },


    paginateURL() {
      const url = new URL(window.location.href);
      url.searchParams.set("page", this.currPage);
      window.history.pushState({}, "", url);
      this.firstLoad();
    },


    openLightbox() {
      this.lightbox = true;
    },
    closeLightbox() {
      console.log("hello");
      this.lightbox = false;
    },

    clearSearch() {
      this.search = "";
      this.selectedProduct = null;
      this.selectedMarket = null;
      this.ETOproject = 'false';
      this.selectedMaterial = null;
      this.currPage = 1;
      this.resetURL();
      this.firstLoad();
    },

    searchReset: function() {
      this.selectedCat = "Show All";
    },

    setPage: function(idx) {
      console.log("setPage " + idx)
      // this.search = "";
      if (idx <= 0 || idx > this.totalPage) {
        return;
      }

      this.currPage = idx;
      this.paginateURL();
    },
  },

  computed: {
    activeNav() {
      return this.$store.state.activePage;
    },
    showButton() {
      if (this.search.trim().length > 0) {
        // console.log('true')
        return true;
      } else {
        // console.log('false')
        return false;
      }
    },
    colorGetter() {
      return this.$store.state.colors;
    },

    searchETO: function() {
      /*
      history.pushState(
        {},
        "",
        `/reports?ac=${this.acFilter}&year=${this.yearFilter}&territory=${
          this.territoryFilter == null ? "all" : this.territoryFilter
        }`
      );
      */
    },

    
    filteredCoolInstalls: function() {
      //set the current page to 0
      // this.currPage = 1;

      return this.coolInstalls;
      //if the search box is filled in

      /*
      if (this.search.trim() !== "") {
        return this.coolInstalls.filter(e => {
          //filter the subject line by what is filled in the search box
          return (
            e.Description.toLowerCase().indexOf(this.search.toLowerCase()) > -1
          );
        });
      }  else {
        return this.coolInstalls;
      }
      */
    },
    

    foundNoCoolInstall: function() {
      if (this.search.length > 1 && this.filteredCoolInstalls.length === 0) {
        //this.noUserFound = true;
        return true;
      }
    },
    pageStart: function() {
      return (this.currPage - 1) * this.countOfPage;
      /*
      if(this.$route.query.page){
        console.log("has query " + (this.$route.query.page - 1) * this.countOfPage)
        return (this.$route.query.page - 1) * this.countOfPage;
        
      } else {
        console.log("no query " + (this.$route.query.page - 1) * this.countOfPage)
        return (this.currPage - 1) * this.countOfPage;
      }
      */
    },
    /*
    totalPage: function() {
      return Math.ceil(this.coolInstalls.length / this.countOfPage);
    },
    */
    firstName() {
      return this.$store.state.user.FirstName;
    },
    lastName() {
      return this.$store.state.user.LastName;
    },
    company() {
      return this.$store.state.user.Company;
    },
    USERID() {
      return this.$store.state.user.UserId;
    },
  },
  created() {
    this.$store.commit("showNavigation");

    // console.log(this.$route.path);
  },
  mounted() {
    this.firstLoad();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #00b4b4;
  border-color: #00b4b4;
}

.coolInstall {
  background-color: #fff;
  line-height: 44px;
  padding: 15px;
  margin-bottom: 5px;
  border-top: 0;
  height: 100%;
  border-radius: 4px;
}

.block {
  margin-top: 15px;
  margin-bottom: 15px;
}
.top-wrap {
  display: flex;
  align-items: flex-start;
}
.coolInstall-bottom {
  align-items: flex-end;
}
.coolInstall-header {
  background: #fff;
  color: #575757;
  display: flex !important;
}
.small-lh {
  line-height: 1.4em;
}

.cool-install-thumb {
  max-width: 300px;
  height: auto;
}

.thumb {
  width: 100%;
  height: 300px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  margin-bottom: 15px;
}
.cool-desc {
  margin-top: 5px;
  min-height: 120px;
}
.search-btns {
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
}
.search-btns button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border: none;
  /* background: #519bb2; */
  background: #4d5967;
  color: #fff;
  border-radius: 4px;
  padding: 5px;
  cursor: pointer;
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 10px;
}
.search-btns .clear {
  margin-right: 0;
  margin-left: auto;
  padding-left: 10px;
  padding-right: 10px;
}
.search-btns .search {
  /*margin-left: auto;*/
  margin-right: 0;
  margin-left: auto;
}
.search-btns button span {
  font-size: 20px;
  width: 20px;
  height: 20px;
  color: #fff;
}
.search-btns button:hover {
  background: #586575;
}
.search-btns button:active {
  background: #586575;
}
.search-btns button:disabled {
  opacity: 0.6;
  cursor: unset !important;
}
.search-btns button:disabled:hover {
  background: #4d5967;
}
.search-btns button:disabled:active {
  background: #4d5967;
}
.right-side {
  text-align: right;
  flex: 1;
}

select.form-control {
  height: auto !important;
  padding: 10px !important;
  color: #757575;
}
.clear-btn {
  position: absolute;
  right: 0;
  top: 14px;
  border: none;
  background: none;
}
.clear-hide {
  display: none !important;
}
.input-wrap {
  position: relative;
  display: block;
}

element.style {
  margin-left: 5px;
}

.badge-light {
  color: #ffffff !important;
  background-color: #575757 !important;
}

.projcard-tagbox {
  font-size: 14px;
  cursor: default;
  user-select: none;
  pointer-events: none;
  margin-bottom: 15px;
}
.projcard-tag {
  display: inline-block;
  background: #e6edf2;
  color: #777;
  border-radius: 3px 0 0 3px;
  line-height: 26px;
  padding: 0 10px 0 13px;
  position: relative;
  margin-right: 20px;
  cursor: default;
  user-select: none;
  transition: color 0.2s;
}
/*
.projcard-tag::before {
  content: '';
  position: absolute;
  background: #fff;
  border-radius: 10px;
  box-shadow: inset 0 1px rgba(0, 0, 0, 0.25);
  height: 6px;
  left: 10px;
  width: 6px;
  top: 10px;
}
*/
.projcard-tag::after {
  content: "";
  position: absolute;
  border-bottom: 13px solid transparent;
  border-left: 10px solid #e6edf2;
  border-top: 13px solid transparent;
  right: -10px;
  top: 0;
}
.not-liked:hover {
  fill: red; /* Change this to the desired color */
}

.single-line {
  /* Limit the text to one line */
  overflow: hidden;
  white-space: nowrap;

  /* Clamp the text size */
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;

  /* Add ellipsis to indicate text overflow */
  text-overflow: ellipsis;
}

.eto-tag {
  position: absolute;
  top: 0px;
  right: 0px;
  color: #fff;
  background: #5f6e7b;
  padding: 10px 20px;
  line-height: 1;
}
.list-btn {
  font-size: 13px !important;
  padding: 5px 7px;
}
.radios {
  color: #fff;
}

/* radios */
.control-group {
  margin-top: 5px;
  display: inline-block;
  vertical-align: top;
  text-align: left;
}
.control {
  display: inline-block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 5px;
  cursor: pointer;
  font-size: 18px;
  margin-right: 10px;
}
.control input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.control__indicator {
  position: absolute;
  top: 2px;
  left: 0;
  height: 20px;
  width: 20px;
  background: #e6e6e6;
}
.control--radio .control__indicator {
  border-radius: 50%;
}
.control:hover input ~ .control__indicator,
.control input:focus ~ .control__indicator {
  background: #ccc;
}
.control input:checked ~ .control__indicator {
  background: #4d5967;
}
.control:hover input:not([disabled]):checked ~ .control__indicator,
.control input:checked:focus ~ .control__indicator {
  background: #4d5967;
}
.control input:disabled ~ .control__indicator {
  background: #e6e6e6;
  opacity: 0.6;
  pointer-events: none;
}
.control__indicator:after {
  content: "";
  position: absolute;
  display: none;
}
.control input:checked ~ .control__indicator:after {
  display: block;
}
.control--checkbox .control__indicator:after {
  left: 8px;
  top: 4px;
  width: 3px;
  height: 8px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.control--checkbox input:disabled ~ .control__indicator:after {
  border-color: #7b7b7b;
}
.control--radio .control__indicator:after {
  left: 7px;
  top: 7px;
  height: 6px;
  width: 6px;
  border-radius: 50%;
  background: #fff;
}
.control--radio input:disabled ~ .control__indicator:after {
  background: #7b7b7b;
}
.page-heading{
  margin-bottom: 0;
}
@media screen and (min-width: 992px){
	.page-heading{
    margin-bottom: 20px;
  }
}

</style>
